var utils = require ("../../utils/utils");

function updateCallback(upd, id) {
	var data = upd.data || this.xml._xmlNodeToJSON(upd.firstChild);
	if(!this.isTaskExists(id))
		return;
	var objData = this.getTask(id);
	for(var key in data) {
		var property = data[key];
		switch(key) {
			case "id":
				continue;
			case "start_date":
			case "end_date":
				property = this.templates.xml_date(property);
				break;
			case "duration":
				objData.end_date = this.calculateEndDate({start_date: objData.start_date, duration: property, task:objData});
				break;
		}
		objData[key] = property;
	}
	this.updateTask(id);
	this.refreshData();
}

function insertCallback(upd, id, parent, mode) {
	var data = upd.data || this.xml._xmlNodeToJSON(upd.firstChild),
		methods = {
			add: this.addTask,
			isExist: this.isTaskExists
		};
	if(mode == "links") {
		methods.add = this.addLink;
		methods.isExist = this.isLinkExists;
	}
	if(methods.isExist.call(this, id))
		return;
	data.id = id;
	methods.add.call(this, data);
}

function deleteCallback(upd, id, parent, mode) {
	var methods = {
		"delete": this.deleteTask,
		"isExist": this.isTaskExists
	};
	if(mode == "links") {
		methods["delete"] = this.deleteLink;
		methods.isExist = this.isLinkExists;
	}
	if(methods.isExist.call(this, id))
		methods["delete"].call(this, id);
}

function patchDataProcessor(gantt, dataprocessor){
	dataprocessor.attachEvent("insertCallback", utils.bind(insertCallback, gantt));
	dataprocessor.attachEvent("updateCallback",  utils.bind(deleteCallback, gantt));
	dataprocessor.attachEvent("deleteCallback",  utils.bind(deleteCallback, gantt));
}

module.exports = patchDataProcessor;