function WorkUnitsCache() {
	this._cache = {};
}

WorkUnitsCache.prototype = {
	// cache previously calculated worktime
	get: function (unit, date) {
		var result = -1;// default value (if not existed in the cache)

		var cache = this._cache;
		if (cache && cache[unit]) {
			var units = cache[unit];
			var time = date.getTime();
			if (units[time] !== undefined)
				result = units[time];
		}
		return result;
	},

	put: function (unit, date, value) {
		if (!unit || !date) return false;

		var cache = this._cache;

		var time = date.getTime();

		value = !!value;

		if (!cache) return false;
		if (!cache[unit]) cache[unit] = {};
		cache[unit][time] = value;
		return true;
	},

	clear: function () {
		this._cache = {};
	}
};

module.exports = WorkUnitsCache;