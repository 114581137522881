var storeRenderCreator = function(name, gantt){
	var store = gantt.getDatastore(name);

	var itemRepainter = {
		renderItem: function(id, renderer){

			var renders = renderer.getLayers();

			var item = store.getItem(id);
			if(item && store.isVisible(id)) {
				for (var i = 0; i < renders.length; i++)
					renders[i].render_item(item);
			}
		},
		renderItems: function(renderer){
			var renderers = renderer.getLayers();
			for (var i = 0; i < renderers.length; i++) {
				renderers[i].clear();
			}

			var data = store.getVisibleItems();

			for (var i = 0; i < renderers.length; i++) {
				renderers[i].render_items(data);
			}
		}
	};

	function skipRepaint(gantt){
		var state = gantt.$services.getService("state");
		if(state.getState("batchUpdate").batch_update){
			return true;
		}else{
			return false;
		}
	}

	store.attachEvent("onStoreUpdated", function(id, item, action){
		if(skipRepaint(gantt)){
			return;
		}

		var renderer = gantt.$services.getService("layers").getDataRender(name);

		if(renderer){
			if(!id || action == "move" || action == "delete"){
				store.callEvent("onBeforeRefreshAll", []);
				itemRepainter.renderItems(renderer);
				store.callEvent("onAfterRefreshAll", []);
			}else{
				store.callEvent("onBeforeRefreshItem", [item.id]);
				itemRepainter.renderItem(item.id, renderer);
				store.callEvent("onAfterRefreshItem", [item.id]);
			}
		}

	});

	// TODO: probably can be done more in a more efficient way
	store.attachEvent("onItemOpen", function(){
		gantt.render();
	});

	store.attachEvent("onItemClose", function(){
		gantt.render();
	});

	function refreshId(renders, oldId, newId, item) {
		for (var i = 0; i < renders.length; i++) {
			renders[i].change_id(oldId, newId);
		}
	}

	store.attachEvent("onIdChange", function(oldId, newId){

		// in case of linked datastores (tasks <-> links), id change should recalculate something in linked datastore before any repaint
		// use onBeforeIdChange for this hook.
		// TODO: use something more reasonable instead
		store.callEvent("onBeforeIdChange", [oldId, newId]);

		if(skipRepaint(gantt)){
			return;
		}
		var renderer = gantt.$services.getService("layers").getDataRender(name);
		refreshId(renderer.getLayers(), oldId, newId, store.getItem(newId));
		itemRepainter.renderItem(newId, renderer);
	});

};

module.exports = {
	bindDataStore: storeRenderCreator
};