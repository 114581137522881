/**
 * The state object for order branch drag and drop
 */

var utils = require("../../../../utils/utils");

module.exports = {
	createDropTargetObject: function createDropTargetObject(parent) {
		var res = {
			targetParent: null,
			targetIndex: 0,
			targetId: null,
			child: false,
			nextSibling: false,
			prevSibling: false
		};

		if (parent) {
			utils.mixin(res, parent, true);
		}
		return res;
	},
	nextSiblingTarget: function nextSiblingTarget(taskId, store) {
		var result = this.createDropTargetObject();
		result.targetId = taskId;
		result.nextSibling = true;
		result.targetParent = store.getParent(result.targetId);
		result.targetIndex = store.getBranchIndex(result.targetId) + 1;
		return result;
	},
	prevSiblingTarget: function prevSiblingTarget(taskId, store) {
		var result = this.createDropTargetObject();
		result.targetId = taskId;
		result.prevSibling = true;
		result.targetParent = store.getParent(result.targetId);
		result.targetIndex = store.getBranchIndex(result.targetId);
		return result;
	},
	firstChildTarget: function firstChildTarget(taskId, store) {
		var result = this.createDropTargetObject();
		result.targetId = taskId;
		result.targetParent = result.targetId;
		result.targetIndex = 0;
		result.child = true;
		return result;
	},
	lastChildTarget: function lastChildTarget(taskId, store) {
		var children = store.getChildren(taskId);
		var result = this.createDropTargetObject();
		result.targetId = children[children.length - 1];
		result.targetParent = taskId;
		result.targetIndex = children.length;
		result.nextSibling = true;
		return result;
	}
};