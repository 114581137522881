module.exports = function(){
	var services = {};
	function register (name, getter){
		services[name] = getter;
	}

	function getService(name){
		if(!services[name]){
			return null;
		}
		return services[name]();
	}

	var servicesEnum = {
		"config": "config",
		"templates": "templates",
		"locale": "locale"
	};

	return {
		services: servicesEnum,
		setService: register,
		getService: getService,
		config: function(){
			return this.getService("config");
		},
		templates: function(){
			return this.getService("templates");
		},
		locale: function(){
			return this.getService("locale");
		},
		destructor: function(){
			for(var i in services){
				if(services[i]){
					var service = services[i];
					if(service && service.destructor){
						service.destructor();
					}
				}
			}
			services = null;
		}
	};
};
