/**
 * resolve dnd position of the task when gantt.config.order_branch_free = false
 */

var dropTarget = require("./drop_target");

function getLast(store){
	var current = store.getNext();
	while(store.exists(current)){

		var next = store.getNext(current);
		if(!store.exists(next)){
			return current;
		}else{
			current = next;
		}
	}
	return null;
}

function findClosesTarget(taskId, allowedLevel, store, up){
	var prev = taskId;
	while(store.exists(prev)){
		var targetLevel = store.calculateItemLevel(store.getItem(prev));
		if((targetLevel === allowedLevel || targetLevel === (allowedLevel - 1)) && store.getBranchIndex(prev) > -1){
			break;
		}else {
			prev = up ? store.getPrev(prev) : store.getNext(prev);
		}
	}

	if(store.exists(prev)){
		if(store.calculateItemLevel(store.getItem(prev)) === allowedLevel){
			return up ? dropTarget.nextSiblingTarget(prev, store) : dropTarget.prevSiblingTarget(prev, store);
		}else{
			return dropTarget.firstChildTarget(prev, store);
		}
	}
	return null;
}

function findTargetAbove(taskId, allowedLevel, store){
	return findClosesTarget(taskId, allowedLevel, store, true);
}
function findTargetBelow(taskId, allowedLevel, store){
	return findClosesTarget(taskId, allowedLevel, store, false);
}

module.exports = function getSameLevelDropPosition(targetTaskId, relTargetPos, eventTop, store, level){
	var result;
	if(targetTaskId !== store.$getRootId()) {
		if (relTargetPos < 0.5) {
			if (store.calculateItemLevel(store.getItem(targetTaskId)) === level) {
				result = dropTarget.prevSiblingTarget(targetTaskId, store);
			} else {
				result = findTargetAbove(targetTaskId, level, store);
				if (result) {
					result = findTargetBelow(targetTaskId, level, store);
				}
			}
		} else {
			if (store.calculateItemLevel(store.getItem(targetTaskId)) === level) {
				result = dropTarget.nextSiblingTarget(targetTaskId, store);
			} else {
				result = findTargetBelow(targetTaskId, level, store);
				if (result) {
					result = findTargetAbove(targetTaskId, level, store);
				}
			}
		}
	}else{
		var rootId = store.$getRootId();
		var rootLevel = store.getChildren(rootId);
		result = dropTarget.createDropTargetObject();
		if(rootLevel.length && eventTop >= 0){
			result = findTargetAbove(getLast(store), level, store);
		}else{
			result = findTargetBelow(rootId, level, store);
		}
	}

	return result;
};
