function createTaskRenderer(gantt){
	var defaultRender = require("./task_bar_render")(gantt);

	return function show_children(task, timeline) {
		if (gantt.isSplitTask(task)) {
			var sub_height = gantt.config.row_height - 5,
				el = document.createElement('div'),
				sizes = gantt.getTaskPosition(task);

			var sub_tasks = gantt.getChildren(task.id);


			for (var i = 0; i < sub_tasks.length; i++) {
				var child = gantt.getTask(sub_tasks[i]);

				var element = defaultRender(child, timeline);
				if(!element)
					continue;

				var padding = Math.floor((gantt.config.row_height - sizes.height) / 2);

				element.style.top = (sizes.top + padding) + "px";
				element.className += " gantt_split_child";

				el.appendChild(element);
			}
			return el;
		}
		return false;
	};
}

module.exports = createTaskRenderer;