module.exports = {
	init: function(controller, grid){
		var gantt = grid.$gantt;

		gantt.attachEvent("onTaskClick", function (id, e) {
			if(gantt._is_icon_open_click(e))
				return true;
			var state = controller.getState();
			var cell = controller.locateCell(e.target);

			if (cell && controller.getEditorConfig(cell.columnName)) {
				if(controller.isVisible() && state.id == cell.id && state.columnName == cell.columnName){
				}else{
					controller.startEdit(cell.id, cell.columnName);
				}
				return false;
			}
			return true;
		});
		gantt.attachEvent("onEmptyClick", function () {
			controller.hide();
			return true;
		});

		gantt.attachEvent("onTaskDblClick", function(id,e){
			var state = controller.getState();
			var cell = controller.locateCell(e.target);
			if(cell && controller.isVisible() && cell.columnName == state.columnName){
				return false;
			}
			return true;
		});
	},

	onShow: function(controller, placeholder, grid){
		if(grid.$getConfig().keyboard_navigation){
			// keyboard navigation extension will handle arrows if enabled
			return;
		}
		var gantt = grid.$gantt;
		placeholder.onkeydown = function (e) {
			e = e || window.event;

			var keyboard = gantt.constants.KEY_CODES;
			if(e.defaultPrevented || (e.shiftKey && e.keyCode != keyboard.TAB)){
				return;
			}

			var shouldPrevent = true;
			switch (e.keyCode) {
				case gantt.keys.edit_save:
					controller.save();
					break;
				case gantt.keys.edit_cancel:
					controller.hide();
					break;
				case keyboard.TAB:
					if(e.shiftKey){
						controller.editPrevCell(true);
					}else{
						controller.editNextCell(true);
					}
					break;
				default:
					shouldPrevent = false;
					break;
			}

			if(shouldPrevent){
				e.preventDefault();
			}
		};
	},
	onHide: function(){

	},

	destroy: function(){

	}
};

