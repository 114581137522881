module.exports = function() {
	return {
		layout: {
			css: "gantt_container",
			rows: [
				{
					cols: [
						{view: "grid", scrollX: "scrollHor", scrollY: "scrollVer"},
						{resizer: true, width: 1},
						{view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
						{view: "scrollbar", id: "scrollVer"}
					]

				},
				{view: "scrollbar", id: "scrollHor", height: 20}
			]
		},
		links: {
			"finish_to_start": "0",
			"start_to_start": "1",
			"finish_to_finish": "2",
			"start_to_finish": "3"
		},
		types: {
			'task': 'task',
			'project': 'project',
			'milestone': 'milestone'
		},
		auto_types: false,
		duration_unit: "day",
		work_time: false,
		correct_work_time: false,
		skip_off_time: false,

		cascade_delete: true,

		autosize: false,
		autosize_min_width: 0,
		autoscroll: true,
		autoscroll_speed: 30,

		show_links: true,
		show_task_cells: true,
		// replace backgroung of the task area with a canvas img
		static_background: false,
		branch_loading: false,
		branch_loading_property: "$has_child",
		show_loading: false,
		show_chart: true,
		show_grid: true,
		min_duration: 60 * 60 * 1000,
		xml_date: "%d-%m-%Y %H:%i",
		api_date: "%d-%m-%Y %H:%i",
		start_on_monday: true,
		server_utc: false,
		show_progress: true,
		fit_tasks: false,
		select_task: true,
		scroll_on_click: true,
		preserve_scroll: true,
		readonly: false,

		/*grid */
		date_grid: "%Y-%m-%d",

		drag_links: true,
		drag_progress: true,
		drag_resize: true,
		drag_project: false,
		drag_move: true,
		drag_mode: {
			"resize": "resize",
			"progress": "progress",
			"move": "move",
			"ignore": "ignore"
		},
		round_dnd_dates: true,
		link_wrapper_width: 20,
		root_id: 0,

		autofit: false, // grid column automatic fit grid_width config
		columns: [
			{name: "text", tree: true, width: '*', resize: true},
			{name: "start_date", align: "center", resize: true},
			{name: "duration", align: "center"},
			{name: "add", width: 44}
		],

		/*scale*/
		step: 1,
		scale_unit: "day",
		scale_offset_minimal: true,
		subscales: [],

		inherit_scale_class: false,

		time_step: 60,
		duration_step: 1,
		date_scale: "%d %M",
		task_date: "%d %F %Y",
		time_picker: "%H:%i",
		task_attribute: "task_id",
		link_attribute: "link_id",
		layer_attribute: "data-layer",
		buttons_left: [
			"gantt_save_btn",
			"gantt_cancel_btn"
		],
		_migrate_buttons: {
			"dhx_save_btn": "gantt_save_btn",
			"dhx_cancel_btn": "gantt_cancel_btn",
			"dhx_delete_btn": "gantt_delete_btn"
		},
		buttons_right: [
			"gantt_delete_btn"
		],
		lightbox: {
			sections: [
				{name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
				{name: "time", type: "duration", map_to: "auto"}
			],
			project_sections: [
				{name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
				{name: "type", type: "typeselect", map_to: "type"},
				{name: "time", type: "duration", readonly: true, map_to: "auto"}
			],
			milestone_sections: [
				{name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
				{name: "type", type: "typeselect", map_to: "type"},
				{name: "time", type: "duration", single_date: true, map_to: "auto"}
			]
		},
		drag_lightbox: true,
		sort: false,
		details_on_create: true,
		details_on_dblclick: true,
		initial_scroll: true,
		task_scroll_offset: 100,

		order_branch: false,
		order_branch_free: false,

		task_height: "full",//number px of 'full' for row height
		min_column_width: 70,

		// min width for grid column (when resizing)
		min_grid_column_width: 70,
		// name of the attribute with column index for resize element
		grid_resizer_column_attribute: "column_index",
		// name of the attribute with column index for resize element
		grid_resizer_attribute: "grid_resizer",

		// grid width can be increased after the column has been resized
		keep_grid_width: false,

		// grid width can be adjusted
		grid_resize: false,

		show_unscheduled: true,

		//
		readonly_property: "readonly",
		editable_property: "editable",
		calendar_property: "calendar_id",
		resource_calendars: {},

		type_renderers: {},

		open_tree_initially: false,
		optimize_render: true,
		prevent_default_scroll: false,
		show_errors: true,
		wai_aria_attributes: true,
		smart_scales: true,
		rtl:false,
		placeholder_task: false
	};
};
