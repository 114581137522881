var domHelpers = require("../../../utils/dom_helpers"),
	utils = require("../../../utils/utils");
var timeout = require("../../../utils/timeout");

function createTaskDND(timeline, gantt){
	var services = gantt.$services;
	return {
		drag: null,
		dragMultiple: {},
		_events: {
			before_start: {},
			before_finish: {},
			after_finish: {}
		},
		_handlers: {},
		init: function () {
			this._domEvents = gantt._createDomEventScope();
			this.clear_drag_state();
			var drag = gantt.config.drag_mode;
			this.set_actions();

			var stateService = services.getService("state");
			stateService.registerProvider("tasksDnd", utils.bind(function(){
				return {
					drag_id : this.drag ? this.drag.id : undefined,
					drag_mode : this.drag ? this.drag.mode : undefined,
					drag_from_start : this.drag ? this.drag.left : undefined
				};
			}, this));

			var evs = {
				"before_start": "onBeforeTaskDrag",
				"before_finish": "onBeforeTaskChanged",
				"after_finish": "onAfterTaskDrag"
			};
			//for now, all drag operations will trigger the same events
			for (var stage in this._events) {
				for (var mode in drag) {
					this._events[stage][mode] = evs[stage];
				}
			}

			this._handlers[drag.move] = this._move;
			this._handlers[drag.resize] = this._resize;
			this._handlers[drag.progress] = this._resize_progress;
		},
		set_actions: function () {
			var data = timeline.$task_data;
			this._domEvents.attach(data, "mousemove", gantt.bind(function (e) {
				this.on_mouse_move(e || event);
			}, this));
			this._domEvents.attach(data, "mousedown", gantt.bind(function (e) {
				this.on_mouse_down(e || event);
			}, this));
			this._domEvents.attach(data, "mouseup", gantt.bind(function (e) {
				this.on_mouse_up(e || event);
			}, this));
		},

		clear_drag_state: function () {
			this.drag = {
				id: null,
				mode: null,
				pos: null,
				start_x: null,
				start_y: null,
				obj: null,
				left: null
			};
			this.dragMultiple = {};
		},
		_resize: function (ev, shift, drag) {
			var cfg = timeline.$getConfig();
			var coords_x = this._drag_task_coords(ev, drag);
			if (drag.left) {
				ev.start_date = gantt.dateFromPos(coords_x.start + shift);
				if (!ev.start_date) {
					ev.start_date = new Date(gantt.getState().min_date);
				}
			} else {
				ev.end_date = gantt.dateFromPos(coords_x.end + shift);
				if (!ev.end_date) {
					ev.end_date = new Date(gantt.getState().max_date);
				}
			}

			if (ev.end_date - ev.start_date < cfg.min_duration) {
				if (drag.left)
					ev.start_date = gantt.calculateEndDate({start_date: ev.end_date, duration: -1, task: ev});
				else
					ev.end_date = gantt.calculateEndDate({start_date: ev.start_date, duration: 1, task: ev});
			}
			gantt._init_task_timing(ev);
		},
		_resize_progress: function (ev, shift, drag) {
			var coords_x = this._drag_task_coords(ev, drag);

			var config = timeline.$getConfig();
			var diffValue = !config.rtl ? (drag.pos.x - coords_x.start) : (coords_x.start - drag.pos.x);

			var diff = Math.max(0, diffValue);
			ev.progress = Math.min(1, diff / Math.abs(coords_x.end - coords_x.start));
		},

		_find_max_shift: function(dragItems, shift){
			var correctShift;
			for(var i in dragItems){
				var drag = dragItems[i];
				var ev = gantt.getTask(drag.id);

				var coords_x = this._drag_task_coords(ev, drag);
				var minX = gantt.posFromDate( new Date(gantt.getState().min_date)),
					maxX = gantt.posFromDate( new Date(gantt.getState().max_date));

				if(coords_x.end + shift > maxX){
					var maxShift = maxX - coords_x.end;
					if(maxShift < correctShift || correctShift === undefined){
						correctShift = maxShift;
					}
				}else if(coords_x.start + shift < minX){
					var minShift = minX - coords_x.start;
					if(minShift < correctShift || correctShift === undefined){
						correctShift = minShift;
					}
				}
			}
			return correctShift;
		},
		_move: function (ev, shift, drag) {
			var coords_x = this._drag_task_coords(ev, drag);
			var new_start = gantt.dateFromPos(coords_x.start + shift),
				new_end = gantt.dateFromPos(coords_x.end + shift);
			if (!new_start) {
				ev.start_date = new Date(gantt.getState().min_date);
				ev.end_date = gantt.dateFromPos(gantt.posFromDate(ev.start_date) + (coords_x.end - coords_x.start));
			} else if (!new_end) {
				ev.end_date = new Date(gantt.getState().max_date);
				ev.start_date = gantt.dateFromPos(gantt.posFromDate(ev.end_date) - (coords_x.end - coords_x.start));
			} else {
				ev.start_date = new_start;
				ev.end_date = new_end;
			}
		},
		_drag_task_coords: function (t, drag) {
			var start = drag.obj_s_x = drag.obj_s_x || gantt.posFromDate(t.start_date);
			var end = drag.obj_e_x = drag.obj_e_x || gantt.posFromDate(t.end_date);
			return {
				start: start,
				end: end
			};
		},
		_mouse_position_change: function (oldPos, newPos) {
			var dx = oldPos.x - newPos.x,
				dy = oldPos.y - newPos.y;
			return Math.sqrt(dx * dx + dy * dy);
		},
		_is_number: function (n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},

		on_mouse_move: function (e) {
			if (this.drag.start_drag) {
				var pos = domHelpers.getRelativeEventPosition(e, gantt.$task_data);

				var sX = this.drag.start_drag.start_x,
					sY = this.drag.start_drag.start_y;

				if ((Date.now() - this.drag.timestamp > 50) ||
					(this._is_number(sX) && this._is_number(sY) && this._mouse_position_change({
						x: sX,
						y: sY
					}, pos) > 20)) {
					this._start_dnd(e);
				}
			}

			var drag = this.drag;

			if (drag.mode) {
				if (!timeout(this, 40))//limit update frequency
					return;

				this._update_on_move(e);

			}
		},

		_update_item_on_move: function(shift, id, mode, drag, e){
			var ev = gantt.getTask(id);
			var original = gantt.mixin({}, ev);
			var copy = gantt.mixin({}, ev);
			this._handlers[mode].apply(this, [copy, shift, drag]);
			gantt.mixin(ev, copy, true);
			//gantt._update_parents(drag.id, true);
			gantt.callEvent("onTaskDrag", [ev.id, mode, copy, original, e]);
			gantt.mixin(ev, copy, true);
			gantt.refreshTask(id);
		},

		_update_on_move: function (e) {
			var drag = this.drag;
			var config = timeline.$getConfig();
			if (drag.mode) {
				var pos = domHelpers.getRelativeEventPosition(e, timeline.$task_data);
				if (drag.pos && drag.pos.x == pos.x)
					return;

				drag.pos = pos;

				var curr_date = gantt.dateFromPos(pos.x);
				if (!curr_date || isNaN(curr_date.getTime()))
					return;


				var shift = pos.x - drag.start_x;
				var ev = gantt.getTask(drag.id);

				if (this._handlers[drag.mode]) {

					if(gantt.isSummaryTask(ev) && gantt.config.drag_project && drag.mode == config.drag_mode.move){

						var initialDrag = {};
						initialDrag[drag.id] = utils.copy(drag);
						var maxShift = this._find_max_shift(utils.mixin(initialDrag, this.dragMultiple), shift);
						if(maxShift !== undefined){
							shift = maxShift;
						}

						this._update_item_on_move(shift, drag.id, drag.mode, drag, e);
						for(var i in this.dragMultiple){
							var childDrag =  this.dragMultiple[i];
							this._update_item_on_move(shift, childDrag.id, childDrag.mode, childDrag, e);
						}
					}else{
						this._update_item_on_move(shift, drag.id, drag.mode, drag, e);
					}
					gantt._update_parents(drag.id);
				}

			}
		},

		on_mouse_down: function (e, src) {
			// on Mac we do not get onmouseup event when clicking right mouse button leaving us in dnd state
			// let's ignore right mouse button then
			if (e.button == 2 && e.button !== undefined)
				return;

			var config = timeline.$getConfig();
			var id = gantt.locate(e);
			var task = null;
			if (gantt.isTaskExists(id)) {
				task = gantt.getTask(id);
			}

			if (gantt.isReadonly(task) || this.drag.mode) return;

			this.clear_drag_state();

			src = src || (e.target || e.srcElement);

			var className = domHelpers.getClassName(src);
			var drag = this._get_drag_mode(className, src);

			if (!className || !drag) {
				if (src.parentNode)
					return this.on_mouse_down(e, src.parentNode);
				else
					return;
			}

			if (!drag) {
				if (gantt.checkEvent("onMouseDown") && gantt.callEvent("onMouseDown", [className.split(" ")[0]])) {
					if (src.parentNode)
						return this.on_mouse_down(e, src.parentNode);

				}
			} else {
				if (drag.mode && drag.mode != config.drag_mode.ignore && config["drag_" + drag.mode]) {
					id = gantt.locate(src);
					task = gantt.copy(gantt.getTask(id) || {});

					if (gantt.isReadonly(task)) {
						this.clear_drag_state();
						return false;
					}

					if ((gantt.isSummaryTask(task) && !config.drag_project) && drag.mode != config.drag_mode.progress) {//only progress drag is allowed for tasks with flexible duration
						this.clear_drag_state();
						return;
					}

					drag.id = id;
					var pos = domHelpers.getRelativeEventPosition(e, gantt.$task_data);

					drag.start_x = pos.x;
					drag.start_y = pos.y;
					drag.obj = task;
					this.drag.start_drag = drag;
					this.drag.timestamp = Date.now();

				} else
					this.clear_drag_state();
			}
		},
		_fix_dnd_scale_time: function (task, drag) {
			var config = timeline.$getConfig();
			var unit = gantt.getScale().unit,
				step = gantt.getScale().step;
			if (!config.round_dnd_dates) {
				unit = 'minute';
				step = config.time_step;
			}

			function fixStart(task) {
				if (!gantt.config.correct_work_time)
					return;
				var config = timeline.$getConfig();
				if (!gantt.isWorkTime(task.start_date, undefined, task))
					task.start_date = gantt.calculateEndDate({
						start_date: task.start_date,
						duration: -1,
						unit: config.duration_unit,
						task: task
					});
			}

			function fixEnd(task) {
				if (!gantt.config.correct_work_time)
					return;
				var config = timeline.$getConfig();
				if (!gantt.isWorkTime(new Date(task.end_date - 1), undefined, task))
					task.end_date = gantt.calculateEndDate({
						start_date: task.end_date,
						duration: 1,
						unit: config.duration_unit,
						task: task
					});
			}

			if (drag.mode == config.drag_mode.resize) {
				if (drag.left) {
					task.start_date = gantt.roundDate({date: task.start_date, unit: unit, step: step});
					fixStart(task);
				} else {
					task.end_date = gantt.roundDate({date: task.end_date, unit: unit, step: step});
					fixEnd(task);
				}
			} else if (drag.mode == config.drag_mode.move) {
				task.start_date = gantt.roundDate({date: task.start_date, unit: unit, step: step});
				fixStart(task);
				task.end_date = gantt.calculateEndDate(task);				
			}
		},
		_fix_working_times: function (task, drag) {
			var config = timeline.$getConfig();
			var drag = drag || {mode: config.drag_mode.move};
			
			if (drag.mode == config.drag_mode.resize) {
				if (drag.left) {
					task.start_date = gantt.getClosestWorkTime({date: task.start_date, dir: 'future', task: task});
				} else {
					task.end_date = gantt.getClosestWorkTime({date: task.end_date, dir: 'past', task: task});
				}
			} else if (drag.mode == config.drag_mode.move) {
				gantt.correctTaskWorkTime(task);
			}
		},

		_finalize_mouse_up: function(taskId, config, drag, e){
			var ev = gantt.getTask(taskId);

			if (config.work_time && config.correct_work_time) {
				this._fix_working_times(ev, drag);
			}

			this._fix_dnd_scale_time(ev, drag);

			if (!this._fireEvent("before_finish", drag.mode, [taskId, drag.mode, gantt.copy(drag.obj), e])) {
				//drag.obj._dhx_changed = false;
				this.clear_drag_state();
				if(taskId == drag.id){
					drag.obj._dhx_changed = false;
					gantt.mixin(ev, drag.obj, true);
				}


				gantt.refreshTask(ev.id);
			} else {
				var drag_id = taskId;

				gantt._init_task_timing(ev);

				this.clear_drag_state();
				gantt.updateTask(ev.id);
				this._fireEvent("after_finish", drag.mode, [drag_id, drag.mode, e]);
			}

		},

		on_mouse_up: function (e) {

			var drag = this.drag;
			if (drag.mode && drag.id) {
				var config = timeline.$getConfig();
				//drop
				var ev = gantt.getTask(drag.id);
				var dragMultiple = this.dragMultiple;

				if(gantt.isSummaryTask(ev) && config.drag_project && drag.mode == config.drag_mode.move){
					for(var i in dragMultiple){
						this._finalize_mouse_up(dragMultiple[i].id, config, dragMultiple[i], e);
					}
				}
				this._finalize_mouse_up(drag.id, config, drag, e);
			}
			this.clear_drag_state();
		},
		_get_drag_mode: function (className, el) {
			var config = timeline.$getConfig();
			var modes = config.drag_mode;
			var classes = (className || "").split(" ");
			var classname = classes[0];
			var drag = {mode: null, left: null};
			switch (classname) {
				case "gantt_task_line":
				case "gantt_task_content":
					drag.mode = modes.move;
					break;
				case "gantt_task_drag":
					drag.mode = modes.resize;

					var dragProperty = el.getAttribute("data-bind-property");

					if (dragProperty == "start_date") {
						drag.left = true;
					} else {
						drag.left = false;
					}
					break;
				case "gantt_task_progress_drag":
					drag.mode = modes.progress;
					break;
				case "gantt_link_control":
				case "gantt_link_point":
					drag.mode = modes.ignore;
					break;
				default:
					drag = null;
					break;
			}
			return drag;

		},

		_start_dnd: function (e) {
			var drag = this.drag = this.drag.start_drag;
			delete drag.start_drag;

			var cfg = timeline.$getConfig();
			var id = drag.id;
			if (!cfg["drag_" + drag.mode] || !gantt.callEvent("onBeforeDrag", [id, drag.mode, e]) || !this._fireEvent("before_start", drag.mode, [id, drag.mode, e])) {
				this.clear_drag_state();
			} else {
				delete drag.start_drag;

				var task = gantt.getTask(id);
				if(gantt.isSummaryTask(task) && gantt.config.drag_project && drag.mode == cfg.drag_mode.move){
					gantt.eachTask(function(child){
						this.dragMultiple[child.id] = gantt.mixin({
							id: child.id,
							obj: child
						}, this.drag);
					}, task.id, this);
				}

				gantt.callEvent("onTaskDragStart", []);
			}

		},
		_fireEvent: function (stage, mode, params) {
			gantt.assert(this._events[stage], "Invalid stage:{" + stage + "}");

			var trigger = this._events[stage][mode];

			gantt.assert(trigger, "Unknown after drop mode:{" + mode + "}");
			gantt.assert(params, "Invalid event arguments");


			if (!gantt.checkEvent(trigger))
				return true;

			return gantt.callEvent(trigger, params);
		},

		round_task_dates: function(task){
			var drag_state = this.drag;
			var config = timeline.$getConfig();
			if (!drag_state) {
				drag_state = {mode: config.drag_mode.move};
			}
			this._fix_dnd_scale_time(task, drag_state);
		},
		destructor: function(){
			this._domEvents.detachAll();
		}
	};
}

function initTaskDND() {
	var _tasks_dnd;
	return {
		extend: function(timeline){
			timeline.roundTaskDates = function (task) {
				_tasks_dnd.round_task_dates(task);
			};

		},
		init: function(timeline, gantt){
			_tasks_dnd = createTaskDND(timeline, gantt);
			// TODO: entry point for touch handlers, move touch to timeline
			timeline._tasks_dnd = _tasks_dnd;
			return _tasks_dnd.init(gantt);
		},
		destructor: function(){
			_tasks_dnd.destructor();
			_tasks_dnd = null;
		}
	};
}

module.exports = {
	createTaskDND: initTaskDND
};
