var utils = require("./utils");

function createScope(addEvent, removeEvent){
	addEvent = addEvent || utils.event;
	removeEvent = removeEvent || utils.eventRemove;

	var handlers = [];

	return {
		attach: function(el, event, handler, capture){
			handlers.push({element: el, event:event, callback: handler, capture: capture});
			addEvent(el, event, handler, capture);
		},
		detach: function(el, event, handler, capture){
			removeEvent(el, event, handler, capture);
			for(var i = 0; i < handlers.length; i++){
				var handler = handlers[i];
				if(handler.element === el && handler.event === event && handler.callback === handler && handler.capture === capture){
					handlers.splice(i, 1);
					i--;
				}
			}
		},
		detachAll: function(){
			for(var i = 0; i < handlers.length; i++){
				removeEvent(handlers[i].element, handlers[i].event, handlers[i].callback, handlers[i].capture);
				removeEvent(handlers[i].element, handlers[i].event, handlers[i].callback, undefined);
				removeEvent(handlers[i].element, handlers[i].event, handlers[i].callback, false);
				removeEvent(handlers[i].element, handlers[i].event, handlers[i].callback, true);
			}
			handlers = [];
		},
		extend: function(){
			return createScope(this.event, this.eventRemove);
		}
	};
}

module.exports = createScope;