var liveUpdateHooks = require("./live_update_hooks");
var helpers = require("../../utils/helpers");

function addDataProcessorHooks(gantt) {

	gantt.dataProcessor = require("./dataprocessor");

	function detachDataProcessor(gantt, dp){
		delete dp.$gantt;
		delete dp.setGanttMode;
		delete dp._getRowData;
		dp.afterUpdate = oldAfterUpdate;

		delete gantt._dp;
		delete gantt._change_id;
		delete gantt._row_style;
		delete gantt._delete_task;
		delete gantt._sendTaskOrder;

		helpers.forEach(dataProcessorHandlers, function(e){
			gantt.detachEvent(e);
		});
		dataProcessorHandlers = [];
	}

	var oldAfterUpdate;
	function extendDataProcessor(gantt, dp){
		dp.setGanttMode = function(mode){
			var modes = dp.modes || {};
			if(dp._ganttMode){
				modes[dp._ganttMode] = {
					_in_progress : dp._in_progress,
					_invalid : dp._invalid,
					updatedRows : dp.updatedRows
				};
			}

			var newState = modes[mode];
			if(!newState){
				newState = modes[mode] = {
					_in_progress : {},
					_invalid : {},
					updatedRows : []
				};
			}
			dp._in_progress = newState._in_progress;
			dp._invalid = newState._invalid;
			dp.updatedRows = newState.updatedRows;
			dp.modes = modes;
			dp._ganttMode = mode;
		};

		oldAfterUpdate = dp.afterUpdate;
		dp.afterUpdate = function(){
			var xml;
			if(arguments.length == 3){
				xml = arguments[1];
			}else{
				// old dataprocessor
				xml = arguments[4];
			}
			var mode = dp._ganttMode;
			var reqUrl = xml.filePath;

			if(this._tMode != "REST" && this._tMode != "REST-JSON"){
				if (reqUrl.indexOf("gantt_mode=links") != -1) {
					mode = "links";
				}else{
					mode = "tasks";
				}
			}else{
				if(reqUrl.indexOf("/link") > reqUrl.indexOf("/task")){
					mode = "links";
				}else{
					mode = "tasks";
				}
			}
			dp.setGanttMode(mode);

			var res = oldAfterUpdate.apply(dp, arguments);
			dp.setGanttMode(mode);
			return res;
		};

		dp._getRowData=gantt.bind(function(id, pref) {
			var task;
			if (dp._ganttMode == "tasks")
				task = this.isTaskExists(id) ? this.getTask(id) : { id: id };
			else
				task = this.isLinkExists(id) ? this.getLink(id) : { id: id };

			task = gantt.copy(task);

			var data = {};
			for (var key in task) {
				if (key.substr(0, 1) == "$") continue;
				var value = task[key];
				if (helpers.isDate(value))
					data[key] = this.templates.xml_format(value);
				else if(value === null)
					data[key] = "";
				else
					data[key] = value;
			}

			var taskTiming = this._get_task_timing_mode(task);
			if(taskTiming.$no_start){
				task.start_date = "";
				task.duration = "";
			}
			if(taskTiming.$no_end){
				task.end_date = "";
				task.duration = "";
			}
			data[dp.action_param] = this.getUserData(id, dp.action_param);
			return data;
		}, gantt);
	}

	function extendGantt(gantt, dp){
		gantt._change_id = gantt.bind(function(oldid, newid) {
			if (dp._ganttMode != "tasks")
				this.changeLinkId(oldid, newid);
			else
				this.changeTaskId(oldid, newid);
		}, this);

		gantt._row_style = function(row_id, classname){
			if (dp._ganttMode != "tasks") return;
			if(!gantt.isTaskExists(row_id))
				return;

			var task = gantt.getTask(row_id);
			task.$dataprocessor_class = classname;
			gantt.refreshTask(row_id);
		};

		// fake method for dataprocessor
		gantt._delete_task = function(row_id, node){};

		gantt._sendTaskOrder = function(id, item){
			if(item.$drop_target){
				dp.setGanttMode("tasks");
				this.getTask(id).target = item.$drop_target;
				dp.setUpdated(id, true,"order");
				delete this.getTask(id).$drop_target;
			}
		};

		this._dp = dp;
	}

	function attachDataProcessorEvents(gantt, dp){
		function clientSideDelete(id){
			var updated = dp.updatedRows.slice();
			var clientOnly = false;

			for(var i = 0; i < updated.length && !dp._in_progress[id]; i++){
				if(updated[i] == id ){
					if(gantt.getUserData(id, "!nativeeditor_status") == "inserted"){
						clientOnly = true;
					}
					dp.setUpdated(id,false);
				}
			}
			return clientOnly;
		}

		function getTaskLinks(task){
			var links = [];

			if (task.$source) {
				links = links.concat(task.$source);
			}
			if (task.$target) {
				links = links.concat(task.$target);
			}

			return links;
		}

		dataProcessorHandlers.push(this.attachEvent("onAfterTaskAdd", function(id, item) {
			if(gantt.isTaskExists(id)){
				dp.setGanttMode("tasks");
				dp.setUpdated(id,true,"inserted");
			}
		}));
		dataProcessorHandlers.push(this.attachEvent("onAfterTaskUpdate", function(id, item) {
			if(gantt.isTaskExists(id)){
				dp.setGanttMode("tasks");
				dp.setUpdated(id,true);

				gantt._sendTaskOrder(id, item);
			}
		}));

		var treeHelper = require("../../utils/task_tree_helpers");
		var cascadeDelete = {};

		dataProcessorHandlers.push(this.attachEvent("onBeforeTaskDelete", function(id, item){
			if(!gantt.config.cascade_delete){
				return true;
			}

			cascadeDelete[id] = {
				tasks: treeHelper.getSubtreeTasks(gantt, id),
				links: treeHelper.getSubtreeLinks(gantt, id)
			};
			return true;
		}));

		dataProcessorHandlers.push(this.attachEvent("onAfterTaskDelete", function(id, item) {
			dp.setGanttMode("tasks");

			// not send delete request if item is not inserted into the db - just remove it from the client
			var needDbDelete = !clientSideDelete(id);
			if(!needDbDelete)
				return;
			
			if(gantt.config.cascade_delete && cascadeDelete[id]){
				var dpMode = dp.updateMode;
				dp.setUpdateMode("off");

				var cascade = cascadeDelete[id];
				for(var i in cascade.tasks){
					if(!clientSideDelete(i)){
						dp.setUpdated(i, true, "deleted");
					}
				}
				dp.setGanttMode("links");
				for(var i in cascade.links){
					if(!clientSideDelete(i)){
						dp.setUpdated(i, true, "deleted");
					}
				}
				cascadeDelete[id] = null;

				if(dpMode != "off"){
					dp.sendAllData();
				}
				dp.setGanttMode("tasks");

				dp.setUpdateMode(dpMode);

			}

			dp.setUpdated(id,true,"deleted");

			if(dp.updateMode != 'off' && !dp._tSend){
				dp.sendAllData();
			}

		}));
		dataProcessorHandlers.push(this.attachEvent("onAfterLinkUpdate", function(id, item) {
			if(gantt.isLinkExists(id)){
				dp.setGanttMode("links");
				dp.setUpdated(id, true);
			}
		}));
		dataProcessorHandlers.push(this.attachEvent("onAfterLinkAdd", function(id, item) {
			if(gantt.isLinkExists(id)){
				dp.setGanttMode("links");
				dp.setUpdated(id, true,"inserted");
			}
		}));
		dataProcessorHandlers.push(this.attachEvent("onAfterLinkDelete", function(id, item) {
			dp.setGanttMode("links");

			var needDbDelete = !clientSideDelete(id);
			if(!needDbDelete)
				return;

			dp.setUpdated(id, true,"deleted");
		}));
		dataProcessorHandlers.push(this.attachEvent("onRowDragEnd", function(id, target) {
			gantt._sendTaskOrder(id, gantt.getTask(id));
		}));

		var tasks = null,
			links = null;
		dataProcessorHandlers.push(this.attachEvent("onTaskIdChange",function(oldId, newId){
			if(!dp._waitMode) return;

			var children = gantt.getChildren(newId);
			if(children.length) {
				tasks = tasks || {};

				for (var i = 0; i < children.length; i++) {
					var ch = this.getTask(children[i]);
					tasks[ch.id] = ch;
				}
			}

			var item = this.getTask(newId),
				itemLinks = getTaskLinks(item);

			if(itemLinks.length) {
				links = links || {};

				for (var i = 0; i < itemLinks.length; i++) {
					var link = this.getLink(itemLinks[i]);
					links[link.id] = link;
				}
			}
		}));

		dp.attachEvent("onAfterUpdateFinish", function(){
			if(tasks || links){
				gantt.batchUpdate(function(){
					for(var id in tasks){
						gantt.updateTask(tasks[id].id);
					}

					for(var id in links){
						gantt.updateLink(links[id].id);
					}
					tasks = null;
					links = null;
				});
				if(tasks) {
					gantt._dp.setGanttMode("tasks");
				}else{
					gantt._dp.setGanttMode("links");
				}
			}
		});

		dp.attachEvent("onBeforeDataSending", function() {
			var url = this._serverProcessor;
			if(this._tMode == "REST-JSON" || this._tMode == "REST"){
				var mode = this._ganttMode.substr(0, this._ganttMode.length - 1);// links, tasks -> /link/id, /task/id

				url = url.substring(0, url.indexOf("?") > -1 ? url.indexOf("?") : url.length);
				//editing=true&
				this.serverProcessor = url + (url.slice(-1) == "/" ? "" : "/") + mode;
			}else{
				this.serverProcessor = url + gantt.ajax.urlSeparator(url) + "gantt_mode=" + this._ganttMode;
			}

			return true;
		});
	}

	var dataProcessorHandlers = [];

	gantt._dp_init = function(dp) {
		gantt.assert(!this._dp, "The dataProcessor is already attached to this gantt instance");

		dp.setTransactionMode("POST", true);
		dp.serverProcessor += (dp.serverProcessor.indexOf("?") != -1 ? "&" : "?") + "editing=true";
		dp._serverProcessor = dp.serverProcessor;
		dp.$gantt = this;
		dp.styles = {
			updated:"gantt_updated",
			order:"gantt_updated",
			inserted:"gantt_inserted",
			deleted:"gantt_deleted",
			invalid:"gantt_invalid",
			error:"gantt_error",
			clear:""
		};

		dp._methods=["_row_style","setCellTextStyle","_change_id","_delete_task"];

		extendDataProcessor.call(this, gantt, dp);
		extendGantt.call(this, gantt, dp);
		attachDataProcessorEvents.call(this, gantt, dp);

		dp.attachEvent("onDestroy", function(){
			detachDataProcessor(gantt, dp);
		});
		liveUpdateHooks(gantt, dp);

		gantt.callEvent("onDataProcessorReady", [dp]);
	};

	gantt.getUserData = function(id, name) {
		if (!this.userdata) this.userdata = {};
		if (this.userdata[id] && this.userdata[id][name]) return this.userdata[id][name];
		return "";
	};
	gantt.setUserData = function(id, name, value) {
		if (!this.userdata) this.userdata = {};
		if (!this.userdata[id]) this.userdata[id] = {};
		this.userdata[id][name] = value;
	};
}

module.exports = addDataProcessorHooks;
