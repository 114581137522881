function createTaskBgRender(gantt){
	//this.getTaskTop
	function _render_bg_line(item, view) {
		var config = view.$getConfig(),
			templates = view.$getTemplates();
		var cfg = view.getScale();
		var count = cfg.count;
		var row = document.createElement("div");
		if (config.show_task_cells) {
			for (var j = 0; j < count; j++) {
				var width = cfg.width[j],
					cssclass = "";

				if (width > 0) {//do not render skipped columns
					var cell = document.createElement("div");
					cell.style.width = (width) + "px";

					cssclass = "gantt_task_cell" + (j == count - 1 ? " gantt_last_cell" : "");
					cssTemplate = templates.task_cell_class(item, cfg.trace_x[j]);
					if (cssTemplate)
						cssclass += " " + cssTemplate;
					cell.className = cssclass;

					row.appendChild(cell);
				}

			}
		}
		var odd = gantt.getGlobalTaskIndex(item.id) % 2 !== 0;
		var cssTemplate = templates.task_row_class(item.start_date, item.end_date, item);
		var css = "gantt_task_row" + (odd ? " odd" : "") + (cssTemplate ? ' ' + cssTemplate : '');

		var store = view.$config.rowStore;
		if(store.getSelectedId() == item.id) {
			css += " gantt_selected";
		}

		//var row = "<div class='" + css + "' " + this.config.task_attribute + "='" + item.id + "'>" + cells.join("") + "</div>";

		row.className = css;

		if (config.smart_rendering) {
			row.style.position = "absolute";
			row.style.top = view.getItemTop(item.id) + "px";
			row.style.width = "100%";
		}
		row.style.height = (config.row_height) + "px";

		if(view.$config.item_attribute){
			row.setAttribute(view.$config.item_attribute, item.id);
		}

		return row;
	}

	return _render_bg_line;
}

module.exports = createTaskBgRender;