var __extends = require("../../../utils/extends");

module.exports = function(gantt) {
	var _super = require("./base_control")(gantt);

	function TimeControl() {
		var self = _super.apply(this, arguments) || this;

		return self; 
	}

	__extends(TimeControl, _super);


	TimeControl.prototype.render = function(sns) {
		var time = gantt.form_blocks.getTimePicker.call(this, sns);
		var html = "<div style='height:" + (sns.height || 30) + "px;padding-top:0px;font-size:inherit;text-align:center;' class='gantt_section_time'>";
		html += time;

		if (sns.single_date) {
			time = gantt.form_blocks.getTimePicker.call(this, sns, true);
			html += "<span></span>";
		} else {
			html += "<span style='font-weight:normal; font-size:10pt;'> &nbsp;&ndash;&nbsp; </span>";
		}

		html += time;
		html += "</div>";
		return html;
	};

	TimeControl.prototype.set_value = function(node, value, ev, config) {
		var cfg = config;
		var s = node.getElementsByTagName("select");
		var map = config._time_format_order;

		if (cfg.auto_end_date) {
			var _update_lightbox_select = function() {
				start_date = new Date(s[map[2]].value, s[map[1]].value, s[map[0]].value, 0, 0);
				end_date = gantt.calculateEndDate({start_date: start_date, duration: 1, task: ev});
				gantt.form_blocks._fill_lightbox_select(s, map.size, end_date, map, cfg);
			};
			for (var i = 0; i < 4; i++) {
				s[i].onchange = _update_lightbox_select;
			}
		}

		var mapping = gantt._resolve_default_mapping(config);

		if (typeof(mapping) === "string") mapping = {start_date: mapping};

		var start_date = ev[mapping.start_date] || new Date();
		var end_date = ev[mapping.end_date] || gantt.calculateEndDate({
			start_date: start_date,
			duration: 1,
			task: ev
		});

		gantt.form_blocks._fill_lightbox_select(s, 0, start_date, map, cfg);
		gantt.form_blocks._fill_lightbox_select(s, map.size, end_date, map, cfg);
	};

	TimeControl.prototype.get_value = function(node, ev, config) {
		var selects = node.getElementsByTagName("select");
		var map = config._time_format_order;
		var needSetTime = gantt.defined(map[3]);
		var startDate;

		function _getEndDate(selects, map, needSetTime, startDate) {
			var endDate = _getDate(selects, map, needSetTime, map.size);

			if (endDate <= startDate) {
				return gantt.date.add(startDate, gantt._get_timepicker_step(), "minute");
			}
			return endDate;
		}

		function _getDate(selects, map, needSetTime, mapOffset) {
			var time;
			var hours = 0;
			var minutes = 0;

			mapOffset = mapOffset || 0;

			if (needSetTime) {
				time = parseInt(selects[map[3] + mapOffset].value, 10);
				hours = Math.floor(time / 60);
				minutes = time % 60;
			}
			return new Date(selects[map[2] + mapOffset].value, selects[map[1] + mapOffset].value, selects[map[0] + mapOffset].value, hours, minutes);
		}

		startDate = _getDate(selects, map, needSetTime);

		if (typeof gantt._resolve_default_mapping(config) === "string") {
			return startDate;
		}

		return {
			start_date: startDate,
			end_date: _getEndDate(selects, map, needSetTime, startDate)
		};
	};

	TimeControl.prototype.focus = function(node) {
		gantt._focus(node.getElementsByTagName("select")[0]);
	};

	return TimeControl;
};