module.exports = function(gantt){
	var modules = [
		require("./autoscroll"),
		require("./batch_update"),
		require("./wbs"),
		require("./jquery_hooks"),
		require("./dhtmlx_hooks"),
		require("./resources"),
		require("./new_task_placeholder"),
		require("./auto_task_types")
	];

	for(var i = 0; i < modules.length; i++){
		if(modules[i])
			modules[i](gantt);
	}
};